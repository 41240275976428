import React from 'react';
import { Typography, Card } from 'antd';
import { aboutMeData } from './SiteData'; // Import the homeData object

const { Title, Paragraph } = Typography;

const AboutMe = () => (
  <div id="about" style={{ display: 'flex', paddingTop: '10px' }}>
    <Card
      title={<Title level={3}>{aboutMeData.title}</Title>}
      headStyle={{ textAlign: 'center' }}
      cover={<img alt={aboutMeData.title} src={aboutMeData.imageUrl} style={{ width: '15%', margin: '0 auto' }} />}
    >
      <Paragraph>{aboutMeData.description}</Paragraph>
    </Card>
  </div>
);

export default AboutMe;
