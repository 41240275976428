// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Layout, Menu, ConfigProvider } from 'antd';
import { HomeOutlined, UserOutlined, AppstoreOutlined, SkinOutlined} from '@ant-design/icons';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import Portfolio from './components/Portfolio';
import BrandsPage from './components/Brands';
import './styles.css';

const { Header, Content, Footer } = Layout;

const theme = {
  token: {
    colorPrimary: 'purple',  // Sets the primary color to purple
    colorLink: 'purple',      // Sets link color to purple
  },
  components: {
    Menu: {
      colorItemBg: 'transparent',
      colorItemTextHover: 'purple',
      colorItemBgActive: 'transparent',
      colorItemTextActive: 'purple',
      colorActiveBar: 'purple',  // Customize active bar color
    },
  },
};

const App = () => (
  <ConfigProvider theme={theme}>
    <Router>
      <Layout>
        <Header>
          <Menu mode="horizontal" defaultSelectedKeys={['home']}>
            <Menu.Item key="home" icon={<HomeOutlined />}>
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="about" icon={<UserOutlined />}>
              <Link to="/about">About Me</Link>
            </Menu.Item>
            <Menu.Item key="portfolio" icon={<AppstoreOutlined />}>
              <Link to="/portfolio">Portfolio</Link>
            </Menu.Item>
            <Menu.Item key="brands" icon={<SkinOutlined />}>
              <Link to="/brands">Brands</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/*" element={<Portfolio />} />
            <Route path="/brands" element={<BrandsPage />} />
          </Routes>
        </Content>
        <Footer>
          Created By Mark Dias 2024
        </Footer>
      </Layout>
    </Router>
  </ConfigProvider>
);

export default App;
