// Portfolio.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Row, Col } from 'antd';
import ProjectCard from './ProjectCard';
import ProjectPage from './ProjectPage';
import { portfolioData } from './SiteData'; // Import portfolioData


const Portfolio = () => {
  return (
    <div id="portfolio" className="section">
      <Routes>
        <Route
          path="/"
          element={
            <Row gutter={[16, 16]}>
              {portfolioData.map((project, index) => (
                <Col key={index} xs={24} sm={12} md={8}>
                  <ProjectCard project={project} />
                </Col>
              ))}
            </Row>
          }
        />
        <Route path="/project/:projectId" element={<ProjectPage />} />
      </Routes>
    </div>
  );
};

export default Portfolio;
