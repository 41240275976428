// ProjectCard.js
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const ProjectCard = ({ project }) => {
  const { title, description, images, elementName } = project;

  return (
    <Link to={`/portfolio/project/${elementName}`}>
      <Card
        hoverable
        cover={<img alt={title} src={images[0]} />}
        style={{ width: 300 }}
      >
        <Meta title={title} description={description} />
      </Card>
    </Link>
  );
};

export default ProjectCard;