function generateImagePaths(elementName, imagePaths) {
  return imagePaths.map(path => `/images/${elementName}${path}`);
}

export const brandsData = {
  title: "Brands I've Worked With",
  description: "",
  elementName: "brands",
  details: "",
  images: generateImagePaths("brands", [
    "/fandf.png",
    "/riverisland.jpeg",
    "/handm.svg",
  ]),
};

export const portfolioData = [
  {
    title: "Coming Soon",
    description: "",
    elementName: "project2",
    details: "",
    images: generateImagePaths("project2", [
      "/images.png",
      "/images.png",
      "/images.png",
    ]),
  },
  {
    title: "Coming Soon",
    description: "",
    elementName: "project3",
    details: "",
    images: generateImagePaths("project3", [
      "/images.png",
      "/images.png",
      "/images.png",
    ]),
  },
  {
    title: "Coming Soon",
    description: "",
    elementName: "project3",
    details: "",
    images: generateImagePaths("project3", [
      "/images.png",
      "/images.png",
      "/images.png",
    ]),
  }
  // ... other projects
];

export const homeData = {
  title: "Coral Dias",
  description: "Freelance Print Designer",
  email: "freelance@coraldias.co.uk",
  instagram: "https://www.instagram.com/corallopesdias",
  linkedin: "https://www.linkedin.com/in/coral-dias-a0155024/",
  whatsapp: "https://wa.me/447951242960",
  coverImage: "/images/home/home.jpeg",
  introText: "This is a portfolio website showcasing the work of Coral Dias, a talented freelance print designer",
  images: generateImagePaths("home", [
    "/images.png",
    "/images.png",
    "/images.png",
  ]),
};

export const aboutMeData = {
  title: "About Me",
  description: "I am a passionate print designer with over 15 years of experience.",
  imageUrl: generateImagePaths("aboutme", [
    "/me.jpeg",
  ]),
};
