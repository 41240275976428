// Brands.js
import React from 'react';
import { Typography } from 'antd';
import "../styles.css";
import { brandsData } from './SiteData'; // Import the brandsData object

const { Title, Paragraph } = Typography;

const Brands = () => (
  <div id="brands">
    <Title level={2}>{brandsData.title}</Title>
    <Paragraph>{brandsData.details}</Paragraph>
    <div className="brands-grid">
      {brandsData.images.map((image, index) => (
        <div key={index} className="brands-grid-item">
          <img alt={`brand-${index}`} src={image} />
        </div>
      ))}
    </div>
  </div>
);

export default Brands;
