// Home.js
import React from 'react';
import { Typography, Card, Row, Col, Image } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { SocialIcon } from 'react-social-icons';
import "../styles.css";
import { homeData } from './SiteData'; // Import the homeData object

const { Title, Paragraph } = Typography;

const Home = () => (
  <div id="home">
    <Title level={2}>{homeData.title}</Title>
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
        <div className="sticky-card">
          <Card
            cover={<img alt={homeData.title} src={homeData.coverImage} />}
            actions={[
              <SocialIcon url={homeData.instagram} style={{ height: 25, width: 25 }} />,
              <SocialIcon url={homeData.linkedin} style={{ height: 25, width: 25 }} />,
              <SocialIcon network="whatsapp" url={homeData.whatsapp} style={{ height: 25, width: 25 }} />
            ]}
          >
            <Card.Meta
              title={homeData.title}
              description={homeData.description}
            />
            <Paragraph>
              <a href={`mailto:${homeData.email}`} target="_blank" rel="noopener noreferrer">
                <MailOutlined key="mail" /> {homeData.email}
              </a>
            </Paragraph>
          </Card>
        </div>
      </Col>
      <Col xs={24} md={16} className="card-container">
        <Card>
          <Paragraph>{homeData.introText}</Paragraph>
          <Row gutter={16} justify="center">
            {homeData.images.map((image, index) => (
              <Col key={index} xs={24} sm={8}>
                <Image width={200} src={image} />
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
    </Row>
  </div>
);

export default Home;