// ProjectPage.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { portfolioData } from './SiteData';
import '../styles.css';

const ProjectPage = () => {
  const { projectId } = useParams();
  const project = portfolioData.find((p) => p.elementName === projectId);

  // Scroll to the top of the page when the component mounts or the projectId changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectId]);

  if (!project) {
    return <div>Project not found</div>;
  }

  const { title, details, images } = project;

  return (
    <div className="project-page">
      <div className="project-details">
        <h2>{title}</h2>
        <p>{details}</p>
      </div>
      <div className="image-grid">
        {images.map((imgSrc, imgIndex) => (
          <div key={imgIndex} className="image-container">
            <img src={imgSrc} alt={`${title} ${imgIndex + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectPage;